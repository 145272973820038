<template>
  <div>
    <a-form ref="form" layout="vertical" :model="form" class="formCon">
      <a-row>
        <a-col :span="12">
          <a-form-item label="签单时间" name="signedTime">
            <a-input :disabled="true" allow-clear v-model:value.trim="form.signedTime" placeholder="">
            </a-input>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="签单机构" name="signedOrg">
            <a-input :disabled="true" allow-clear v-model:value.trim="form.signedOrg" placeholder="">
            </a-input>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <a-form-item label="返佣时间" name="rebateTime">
            <a-input :disabled="true" allow-clear v-model:value.trim="form.rebateTime" placeholder="">
            </a-input>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="返佣金额" name="rebateAmount">
            <a-input :disabled="true" allow-clear v-model:value.trim="form.rebateAmount" placeholder="">
            </a-input>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <a-form-item label="业务归属人" name="bizAttributionPersonName">
            <a-input :disabled="true" allow-clear v-model:value.trim="form.bizAttributionPersonName" placeholder="">
            </a-input>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="客资归属人" name="customerAttributionPersonName">
            <a-input :disabled="true" allow-clear v-model:value.trim="form.customerAttributionPersonName"
              placeholder="">
            </a-input>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <a-form-item label="分配人" name="allocatePersonName">
            <a-input :disabled="true" allow-clear v-model:value.trim="form.allocatePersonName" placeholder="">
            </a-input>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="客户来源" name="customerSource">
            <a-input :disabled="true" allow-clear v-model:value.trim="form.customerSource" placeholder="">
            </a-input>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="8">
          <a-form-item label="业务归属人" name="bizAttributionPersonemployeeId">
            <a-select @change="bizAttributionPerson($event)" v-model:value="form.bizAttributionPerson.employeeId"
              placeholder="请选择业务归属人" allow-clear show-search :filter-option="filterOption" style="width: 100%">
              <a-select-option v-for="option in list" :key="option.id" :name="option.name"
                :disabled="option.disabled">{{ option.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="4">
          <a-form-item label="提成比例" name="bizAttributionPersonrate">
            <a-input-number max="100" min="0" addon-after="%"
              v-model:value="form.bizAttributionPerson.rate"></a-input-number>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="8">
          <a-form-item label="客资归属人" name="customerAttributionPersonemployeeId">
            <a-select @change="customerAttributionPerson($event)"
              v-model:value="form.customerAttributionPerson.employeeId" show-search :filter-option="filterOption" placeholder="请选择客资归属人" allow-clear
              style="width: 100%">
              <a-select-option v-for="option in list" :key="option.id" :name="option.name"
                :disabled="option.disabled">{{ option.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="4">
          <a-form-item label="提成比例" name="customerAttributionPersonrate">
            <a-input-number max="100" min="0" addon-after="%"
              v-model:value="form.customerAttributionPerson.rate"></a-input-number>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="8">
          <a-form-item label="客服" name="allocatePersonemployeeId">
            <a-select @change="allocatePerson($event)" show-search :filter-option="filterOption" v-model:value="form.allocatePerson.employeeId"
              placeholder="请选择客服" allow-clear style="width: 100%">
              <a-select-option v-for="option in list" :key="option.id" :name="option.name"
                :disabled="option.disabled">{{ option.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="4">
          <a-form-item label="提成比例" name="allocatePersonrate">
            <a-input-number max="100" min="0" addon-after="%" v-model:value="form.allocatePerson.rate"></a-input-number>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="8">
          <a-form-item label="运营" name="customerSourcePersonemployeeId">
            <a-select @change="customerSourcePerson($event)" show-search :filter-option="filterOption" v-model:value="form.customerSourcePerson.employeeId"
              placeholder="请选择运营" allow-clear style="width: 100%">
              <a-select-option v-for="option in list" :key="option.id" :name="option.name"
                :disabled="option.disabled">{{ option.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="4">
          <a-form-item label="提成比例" name="customerSourcePersonrate">
            <a-input-number max="100" min="0" addon-after="%"
              v-model:value="form.customerSourcePerson.rate"></a-input-number>
          </a-form-item>
        </a-col>
      </a-row>
      <!-- <a-row>
        <a-col :span="8">
          <a-form-item label="主播" name="broadcastPersonemployeeId">
            <a-select @change="broadcastPerson($event)" show-search :filter-option="filterOption" v-model:value="form.broadcastPerson.employeeId" placeholder="请选择主播" allow-clear style="width: 100%">
              <a-select-option v-for="option in list" :key="option.id" :name="option.name"
                :disabled="option.disabled">{{ option.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="4">
          <a-form-item label="提成比例" name="broadcastPersonrate">
            <a-input-number max="100" min="0" addon-after="%" v-model:value="form.broadcastPerson.rate"></a-input-number>
          </a-form-item>
        </a-col>
      </a-row> -->
      <!--管理层-->
      <a-row v-for="(item, index) in form.managerList" :key="index">
        <a-col :span="8">
          <a-form-item :name="item.employeeId + index">
            <div class="label-style" slot="label">
              <div>
                <span>管理层{{ index + 1 }}</span>
                <DeleteOutlined v-if="index > 0" @click="deleteManage(index)"
                  style="color: #1677ff; font-size: 16px; margin-left: 6px; cursor: pointer;" />
              </div>
            </div>
            <a-select @change="managerList($event, index)" show-search :filter-option="filterOption" v-model:value="item.employeeId" placeholder="请选择管理层" allow-clear style="width: 100%">
              <a-select-option v-for="option in list" :key="option.id" :name="option.name"
                :disabled="option.disabled">{{ option.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="4">
          <a-form-item label="提成比例" :name="item.rate + index">
            <a-input-number max="100" min="0" addon-after="%" v-model:value="item.rate"></a-input-number>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24">
          <a-button @click="addManage" type="primary" ghost style="margin-bottom: 20px;">添加管理层</a-button>
        </a-col>
      </a-row>
      <a-form-item>
        <a-button type="primary" @click="submit" :loading="submitLoading">保存</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import TablePage from "@/components/TablePage";
// import { starRating, productType } from '@/utils/public';
import dayjs from 'dayjs';
export default {
  components: { TablePage },
  props: ['selectedRows'],
  name: "institutionsCustomerDetail",
  data() {
    return {
      labelCol: { span: 10 },
      wrapperCol: { span: 14 },
      submitLoading: false,
      form: {
        bizAttributionPerson:{
          employeeId: null,
            id: null,
            name: null,
            rate: null
        },
        customerAttributionPerson:{
          employeeId: null,
            id: null,
            name: null,
            rate: null
        },
        allocatePerson:{
          employeeId: null,
            id: null,
            name: null,
            rate: null
        },
        customerSourcePerson:{
          employeeId: null,
            id: null,
            name: null,
            rate: null
        },
        broadcastPerson:{
          employeeId: null,
            id: null,
            name: null,
            rate: null
        },
        managerList: [
          {
            employeeId: null,
            id: null,
            name: null,
            rate: 0
          }
        ]
      },
      openModal: false,
      showModal: false,
      modalTitle: '',
      modalContent: '',
      counting: false,
      countdown: 5,
      countdownInterval: null,
      startDisabled: false,
      isComfirm: false,
      domain: {},

      attributablePersonList: [],
      followUpPeopleIdList: [],
      signingItemList: [],
      orderAmount: [],
      signingItemList: [],
      source: {},
      list: [],
    }
  },
  created() {
    this.getDetail(this.selectedRows[0].id)

  },
  methods: {
    disabledDate(time) {
      return time > Date.now();
    },

    async getDetail() {
      try {
        const res = await this.$http.get('/approval/rebateAudit/getById', {
          id: this.selectedRows[0].id,
        });
        this.list = res.employeesList;
        this.form = res.data;
        this.form.signedTime = this.form.signedTime ? dayjs(this.form.signedTime).format('YYYY-MM-DD HH:mm:ss') : ''
        this.form.rebateTime = this.form.rebateTime ? dayjs(this.form.rebateTime).format('YYYY-MM-DD HH:mm:ss') : ''
        if (!this.form.bizAttributionPerson) {
          this.form.bizAttributionPerson = {
            employeeId: null,
            id: null,
            name: null,
            rate: null
          }
        }
        if (!this.form.customerAttributionPerson) {
          this.form.customerAttributionPerson = {
            employeeId: null,
            id: null,
            name: null,
            rate: null
          }
        }
        if (!this.form.allocatePerson) {
          this.form.allocatePerson = {
            employeeId: null,
            id: null,
            name: null,
            rate: null
          }
        }
        if (!this.form.customerSourcePerson) {
          this.form.customerSourcePerson = {
            employeeId: null,
            id: null,
            name: null,
            rate: null
          }
        }
        if (!this.form.broadcastPerson) {
          this.form.broadcastPerson = {
            employeeId: null,
            id: null,
            name: null,
            rate: null
          }
        }

        this.form.managerList = this.form.managerList ? this.form.managerList : [{
          employeeId: null,
          id: null,
          name: null,
          rate: 0
        }];
      } catch ({ message }) {
        this.$message.error(message);
      }
    },

    filterSouOption(input, option) {
      return option.text.toLowerCase().indexOf(input.trim().toLowerCase()) >= 0;
    },

    changeContent(e) {
      this.currContent = this.pushContent.filter(item => item.id == e)[0];
    },

    async submit() {
      try {
        await this.$refs.form.validate();
      } catch (e) { // 未通过表单验证
        return;
      }
      this.submitLoading = true;
      let { orderNo, shouldRebateAmount, signedTime, signedOrg, rebateTime, rebateAmount, bizAttributionPerson, customerAttributionPerson, allocatePerson, customerSourcePerson, broadcastPerson, managerList } = this.form
      try {
        await this.$http.post('/approval/rebateAudit/pass', { id: this.selectedRows[0].id, shouldRebateAmount, orderNo, signedTime, signedOrg, rebateTime, rebateAmount, bizAttributionPerson, customerAttributionPerson, allocatePerson, customerSourcePerson, broadcastPerson, managerListStr: JSON.stringify(managerList) });
        this.$message.success('提交成功');
        this.$refs.form.resetFields();
        this.$emit('close');
      } catch ({ message }) {
        this.$message.error(message);
      }
      this.submitLoading = false;
    },

    handleOk() {
      this.openModal = false;
    },

    addManage() {
      if (this.form.managerList.length < 5 || !this.form.managerList) {
        this.form.managerList.push({
          employeeId: null,
          id: null,
          name: null,
          rate: 0
        })
      } else {
        return
      }
    },

    deleteManage(index) {
      this.form.managerList.splice(index, 1)
    },

    bizAttributionPerson(e) {
      this.form.bizAttributionPerson.name = this.list.filter(item => item.id == e)[0].name
    },

    customerAttributionPerson(e) {
      this.form.customerAttributionPerson.name = this.list.filter(item => item.id == e)[0].name
    },

    allocatePerson(e) {
      this.form.allocatePerson.name = this.list.filter(item => item.id == e)[0].name
    },

    customerSourcePerson(e) {
      this.form.customerSourcePerson.name = this.list.filter(item => item.id == e)[0].name
    },

    broadcastPerson(e) {
      this.form.broadcastPerson.name = this.list.filter(item => item.id == e)[0].name
    },

    managerList(e, index) {
      this.form.managerList[index].name = this.list.filter(item => item.id == e)[0].name
    },

    filterOption(input, option) {
      return option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
  }
}
</script>

<style scoped lang="scss">
.blueCopy {
  color: blue;
  text-decoration: underline;
}

.ic-title {
  font-size: 16px;
  font-weight: bold;
  padding-bottom: 20px;
}

.formCon {
  .ant-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;

    .ant-col {
      width: 48%;
      margin: 0 5px;
      flex: 1;
    }
  }
}

.label-style {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.ls-required {
  color: red;
  margin-right: 4px;
}

.ls-icon {
  margin-left: 4px;
  font-size: 14px;
  color: #999999;
}

.ma-col {
  border: 1px solid #d9d9d9;
  padding: 20px 20px 0 20px;
  border-radius: 8px;

  .ms-flex {
    display: flex;
    margin-bottom: 20px;
  }

  .ms-title {
    width: 90px;
    text-align: right;

    flex-shrink: 0;
  }

  .ms-content {
    flex-shrink: 0;
    width: calc(100% - 130px);
  }

  .ms-scroll {
    flex-shrink: 0;
    width: calc(100% - 130px);
    max-height: 200px;
    overflow-y: scroll;
  }

  .ms-img {
    width: 160px;
  }
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background: none;
  /*可以设置轨道颜色*/
  padding-right: 2px;
}

/*滚动条*/
::-webkit-scrollbar-thumb {
  background: rgba(142, 142, 142, 0.4);
  border-radius: 6px;
}
</style>
